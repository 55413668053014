import Vue from "vue";
import VueRouter from "vue-router";
import business from "./business";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: '/zcy'
  },
  {
    path: "/zcy",
    name: "ZCY",
    component: () => import("@/views/index.vue"),
    redirect: '/zcy/home',
    children: [
      ...business
    ]
  },
  // 
  {
    path: "/zcy/login",
    name: "LoginPage",
    meta: { menuName: "LoginPage" },
    component: () => import("@/views/LoginPage"),
  },
  {
    path: "/zcy/register",
    name: "RegisterPage",
    meta: { menuName: "RegisterPage" },
    component: () => import("@/views/RegisterPage"),
  },
  {
    path: "/zcy/supplierAllBack",
    name: "SupplierAllBack",
    component: () => import("@/views/supplierAllBack.vue"),
  },
  { path: "*", redirect: "/404" },
  {
    path: "/404",
    component: () => import("@/views/404.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    // 页面刷新回滚顶部
    return {
      x: 0,
      y: 0
    }
  },
});

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

export default router;
