import { getData, setData } from "@/utils/localStoreUtil";
import {
  TOKEN,
  USER_INFO,
  REGION_TREE,
  TAG_DATA,
  POLICY_YEAR,
  SYS_INDUSTRY,
  USER_LOCAL,
} from "@/param/method_param";

const personOptions = {
  namespaced: true,
  state: {
    // 用户信息
    token: getData(TOKEN) || "",
    userInfo: getData(USER_INFO) || {},
    // 用户所属地区
    userLocal: getData(USER_LOCAL) || {},
    // 字典数据
    regionTree: getData(REGION_TREE) || [],
    tagData: getData(TAG_DATA) || {},
    policyYear: getData(POLICY_YEAR) || [],
    sysIndustry: getData(SYS_INDUSTRY) || [],
  },
  mutations: {
    // 用户信息
    SET_TOKEN(state, value) {
      state.token = value;
    },
    SET_USER_INFO(state, value) {
      state.userInfo = value;
    },
    SET_USER_LOCAL(state, value) {
      state.userLocal = value
    },
    // 字典数据
    SET_REGION_TREE(state, value) {
      state.regionTree = value;
    },
    SET_TAG_DATA(state, value) {
      state.tagData = value;
    },
    SET_POLICY_YEAR(state, value) {
      state.policyYear = value;
    },
    SET_SYS_INDUSTRY(state, value) {
      state.sysIndustry = value;
    },
  },
  actions: {
    // 用户信息
    setToken(context, value) {
      setData(TOKEN, value);
      context.commit('SET_TOKEN', value);
    },
    setUserInfo(context, value) {
      setData(USER_INFO, value);
      context.commit('SET_USER_INFO', value);
    },
    setUserLocal(context, value) {
      setData(USER_LOCAL, value);
      context.commit('SET_USER_LOCAL', value);
    },
    // 字典数据
    setRegionTree(context, value) {
      setData(REGION_TREE, value);
      context.commit("SET_REGION_TREE", value);
    },
    setTagData(context, value) {
      setData(TAG_DATA, value);
      context.commit("SET_TAG_DATA", value);
    },
    setPolicyYear(context, value) {
      setData(POLICY_YEAR, value);
      context.commit("SET_POLICY_YEAR", value);
    },
    setSysIndustry(context, value) {
      setData(SYS_INDUSTRY, value);
      context.commit("SET_SYS_INDUSTRY", value);
    },
  },
  getters: {
    _isLogin(state) {
      if (state.token === "") {
        return false;
      } else {
        return true;
      }
    }
  }
}

export default personOptions;
