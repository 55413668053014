const commonParam = {
  // 服务器路径
  // serverUrl: 'http://192.168.1.111:9999/',
  // serverUrl: "http://192.168.1.214:25000",
  // serverUrl: 'http://192.168.1.116:9999/',
  serverUrl: "https://zc-api.smesc.cn",
  // 图片地址接口地址
  // showImgUrl: 'http://192.168.1.212/smesc',
  showImgUrl: 'https://eps.smesc.cn/img',
  // 注册地址
  // registerUrl: 'http://192.168.1.100:26000',
  // registerUrl: 'http://192.168.1.214:25000',
  registerUrl: 'https://api3.smesc.cn',
  //密码加密公钥
  getPublicKeyUrl: "uaa/public-key",
  // 刷新token地址
  refreshTokenUrl: 'uaa/login',
  // 文件下载路径
  downloadUrl: '/download',
  // 系统名称
  systemName: "zcy-portal",
  // 操作方式
  handleType: {
    addType: 'ADD',
    updateType: 'UPDATE',
    lookType: 'LOOK'
  },
  // token过期时间(毫秒)比服务器值减少1分钟
  tokenOverdueTime: 1 * 60 * 60 * 1000,
  // 刷新token过期时间(毫秒)比服务器值减少1分钟
  refreshTokenOverdueTime: 3 * 60 * 60 * 1000,
  // 页眉名字 -- 四川省中小企业公共服务平台
  headerName: "益企云",
  // 首页左上角名字
  tabName: '政策服务云平台',
  // 登录页面名字
  loginPlatformName: '益企云科技股份有限公司',
  // 公司名称
  systemCompany: '益企云科技股份有限公司',
  // 公司联系电话
  systemTel: "028-64055852",
  // 备案号
  systemRecord: '蜀ICP备17040581号-1',
  // 指导单位
  guidingUnit: '中华人民共和国工业和信息化部',
  // 主管单位
  competentUnit: '四川省经济和信息化厅',
  // 系统脚下名字
  footerText: '系统版本v1.0&nbsp;&copy;&nbsp;2024&nbsp;',
  // 限制接口权限页面展示,目前国土项目不需要接口细粒度控制，
  flgBtn: false,
  // 注册跳转地址
  // registerUrl: 'https://pltf.smesc.cn/register#/register',
  // findPasswordUrl: 'https://pltf.smesc.cn/register#/findPassword',
  smescUrl: 'https://www.yiqicloud.com.cn',
};

export default commonParam;
