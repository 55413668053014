const business = [
  /* {
    path: "/zcy/home",
    name: "HomePage",
    meta: { menuName: "HomePage" },
    component: () => import("@/views/HomePage"),
  }, */
  {
    path: "/zcy/home",
    name: "HomePage",
    meta: { menuName: "HomePage" },
    component: () => import("@/views/NewHomePage"),
  },
  {
    path: "/zcy/policyDocument",
    name: "PolicyDocumentPage",
    meta: { menuName: "PolicyDocumentPage" },
    component: () => import("@/views/PolicyDocument"),
  },
  {
    path: "/zcy/policyDocumentDetail",
    name: "PolicyDocumentDetailPage",
    meta: { menuName: "PolicyDocumentPage" },
    component: () => import("@/views/PolicyDocument/detailPage"),
  },
  {
    path: "/zcy/noticeAnnouncement",
    name: "NoticeAnnouncementPage",
    meta: { menuName: "NoticeAnnouncementPage" },
    component: () => import("@/views/NoticeAnnouncement"),
  },
  {
    path: "/zcy/noticeAnnouncementDetail",
    name: "NoticeAnnouncementDetailPage",
    meta: { menuName: "NoticeAnnouncementPage" },
    component: () => import("@/views/NoticeAnnouncement/detailPage"),
  },
  {
    path: "/zcy/policyFile",
    name: "PolicyFilePage",
    meta: { menuName: "PolicyFilePage" },
    component: () => import("@/views/PolicyFile"),
  },
  {
    path: "/zcy/policyFileDetail",
    name: "PolicyFileDetailPage",
    meta: { menuName: "PolicyFilePage" },
    component: () => import("@/views/PolicyFile/detailPage"),
  },
  {
    path: "/zcy/policyRead",
    name: "PolicyReadPage",
    meta: { menuName: "PolicyReadPage" },
    component: () => import("@/views/PolicyRead"),
  },
  {
    path: "/zcy/policyReadDetail",
    name: "PolicyReadDetailPage",
    meta: { menuName: "PolicyReadPage" },
    component: () => import("@/views/PolicyRead/detailPage"),
  },
  {
    path: "/zcy/policyDeclare",
    name: "PolicyDeclarePage",
    meta: { menuName: "PolicyDeclarePage" },
    component: () => import("@/views/PolicyDeclare"),
  },
  {
    path: "/zcy/policyDeclareDetail",
    name: "PolicyDeclareDetailPage",
    meta: { menuName: "PolicyDeclarePage" },
    component: () => import("@/views/PolicyDeclare/detailPage"),
  },
  {
    path: "/zcy/policyMatch",
    name: "PolicyMatchPage",
    meta: { menuName: "PolicyMatchPage" },
    component: () => import("@/views/PolicyMatch"),
  },
  {
    path: "/zcy/policyMatchDetail",
    name: "PolicyMatchDetailPage",
    meta: { menuName: "PolicyMatchPage" },
    component: () => import("@/views/PolicyMatch/detailPage"),
  },
  {
    path: "/zcy/policyAssembly",
    name: "PolicyAssemblyPage",
    meta: { menuName: "PolicyAssemblyPage" },
    component: () => import("@/views/PolicyAssembly"),
  },
  {
    path: "/zcy/policyDemand",
    name: "PolicyDemandPage",
    meta: { menuName: "PolicyDemandPage" },
    component: () => import("@/views/PolicyDemand"),
  },
  {
    path: "/zcy/policyPanorama",
    name: "PolicyPanoramaPage",
    meta: { menuName: "PolicyPanoramaPage" },
    component: () => import("@/views/PolicyPanorama"),
  },
  {
    path: "/zcy/userCenter",
    name: "UserCenterPage",
    meta: { menuName: "UserCenterPage" },
    component: () => import("@/views/UserCenter"),
  },
  {
    path: "/zcy/userCenter/personal",
    name: "PersonalPage",
    meta: { menuName: "PersonalPage" },
    component: () => import("@/views/Personal"),
  },
  /* { path: "*", redirect: "/404" },
  {
    path: "/404",
    component: () => import("@/views/404.vue"),
  }, */
]

export default business;