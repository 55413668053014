// token
export const TOKEN = "token";

// token获取时间
export const TOKEN_TIME = "tokenTime";

// 刷新token
export const REFRESH_TOKEN = "refreshToken";
//
export const USER_MENU = "userMenu";
export const USER_INFO = "userInfo";
// 地区
export const DISTRICT = "district";
// 行业
export const INDUSTRY = "industry";
// 字典类型
export const DICT_TYPE = "dict_type";
// 企业注册类型
export const ECON_TYPE = "econ_type";
// 企业控股类型
export const CTRL_TYPE = "ctrl_type";

// 主管部门
export const GOV = "gov";
// 字典数据
export const DICT_MAP = {
  CARDTYPE: "CARDTYPE",
};
// 系统参数版本（登录时验证是否需要重复获取参数）
export const SYSTEM_VERSION = "systemVersion";
// 用户菜单角色
export const MENU_ROLE = "menuRole";

// tabs - 导航栏打开页签记录
export const TABS = "tabs";
// breadcrumb - 面包屑标签内容
export const BREAD_CRUMB = "breadCrumb";

export const ACTIVED_MENU = "activedMenu";

export const REGION_TREE = "regionTree";
export const TAG_DATA = "tagData";
export const POLICY_YEAR = "policyYear";

export const CLICK_ITEM = "clickItem";
export const DETAIL_DATA = "detailData";

export const SEARCH_KEY = "searchKey";

export const SUPPLY_TYPE = "supplyType";
export const DEMAND_TYPE = "demandType";
export const SERVICE_TYPE = "serviceType";
export const SYS_INDUSTRY = "sysIndustry";
export const USER_LOCAL = "userLocal";