import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/css/reset.css";
import "@/assets/css/public.css";
import "@/assets/css/el.css";
import 'font-awesome/css/font-awesome.min.css';
import Print from 'vue-print-nb';

Vue.config.productionTip = false;
Vue.use(ElementUI, { size: 'small' });
Vue.use(Print)

//引入 echarts
import * as echarts from 'echarts';
import 'echarts-wordcloud';
Vue.prototype.$echarts = echarts;

// 进入mathJs计算
import * as mathjs from 'mathjs';
Vue.prototype.$math = mathjs;
Vue.use(echarts);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
